import React, {useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setMuted, setUrl }  from '../soundsSlice';
import { setFiles, setError } from './shopSlice';
import { Button, Image, Modal  } from 'react-bootstrap';
import { config } from '../+Global'
import axios from 'axios';

export const Produktfiles = () => {
//  const files = useSelector( state => state.shop.files )
  const dispatch = useDispatch()

  axios
    .post('https://backend.weltmusik-vienna.at/files.php' )
    .then(  res => { dispatch(setFiles(res.data)) } )
    .catch( err => { dispatch(setError(err.message)) } )
  
  return ( '' )
}

export const BildJPG = ( props ) => {
  const files = useSelector( state => state.shop.files );
  const found = files.find( (file) => file === props.BestNr + ".jpg" );
  let file = '';
  (found==null) ? file="/resources/default.jpg" : file=config.uploadPath + found;
  return (
    <Image 
      className={props.className}
      src = {file}
    />
  )
}

export const ButtonMP3 = ( props ) => {
  const dispatch = useDispatch()
  const files = useSelector( state => state.shop.files );
  const found = files.find( (file) => file === props.BestNr + ".mp3" );
  if (found == null) {
    return ( "" )
  } else {
    return ( 
      <Button
        className={props.className}
        onClick={ () => { 
          dispatch( setUrl( config.uploadPath + found ) );
          dispatch( setMuted( false ) );
        } }
      > 
        Hörprobe
      </Button> )
  }
}

export const ButtonPDF = ( props ) => {
  const files = useSelector( state => state.shop.files );
  const found = files.find( (file) => file === props.BestNr + ".pdf" );
  const [show, setShow] = useState(false);

  if (found == null) {
    return ( '' )
  } else {
    return ( 
      <div>
        <Button className={props.className} onClick={ () => setShow(true) }>
          Anschauen 
        </Button>
        <Modal show={show} onHide={ () => setShow(false) }>
          <Modal.Header closeButton>
            <Modal.Title> {props.Titel} </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <embed 
              src={config.uploadPath + found} 
              height="560"
              width="100%"
            />
          </Modal.Body>
        </Modal>
      </div>
    )
  }
  
}

export default Produktfiles;
