import React, { createRef } from 'react';
import { useNavigate } from "react-router-dom";
import { Container, Row, Col,  Form, Button } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { putCart } from './shopSlice'
import { BildJPG, ButtonPDF, ButtonMP3 } from './Produktfiles';
import { NavButton } from '../Buttons'
import './Shop.scss'

const Produktdetails = (p) => {
  const refMenge = createRef()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const x =  useSelector( state => state.shop.curprod );

  const handleAnzClick = () => {
    curProd.Menge = refMenge.current.value;
    dispatch( putCart( curProd ) );
    navigate('/warenkorb');
  }

  let curProd = JSON.parse(JSON.stringify(x)); 

  return (
    <Container id="Produktdetails" fluid>
      <Row id="Steuerung">
        <Col className="Seitentitel"> Produktdetails </Col>
        <Col>
          <NavButton path="/bestellung" caption="Bestellung" variant="gold"/>
          <NavButton path="/auswahl" caption="Auswahl" variant="gold"/>
        </Col>
      </Row>
      <Row className="mt-3 gold"><Col><h2>{curProd.Titel}</h2></Col></Row>
      <Row><Col>{curProd.Inhalt}</Col></Row>
      <Row className="mt-3">
        <Col sm="7">
          <Row><Col>Komponist:</Col><Col>{curProd.Komponist}</Col></Row>
          <Row><Col>Autor:</Col><Col>{curProd.Autor}</Col></Row>
          <Row><Col>Verlag:</Col><Col>{curProd.Verlag}</Col></Row>
          <Row><Col>Arrangeur:</Col><Col>{curProd.Arrangeur}</Col></Row>
          <Row><Col>Interpret, Orchester:</Col><Col>{curProd.IntOrch}</Col></Row>
          <Row><Col>Verkaufseinschränkung:</Col><Col>{curProd.VerkEinsch}</Col></Row>
          <Row><Col>Produktgruppe:</Col><Col>{curProd.Gruppe}</Col></Row>
          <Row><Col>Ausgabe:</Col><Col>{curProd.Ausgabe}</Col></Row>
          <Row><Col>Art:</Col><Col>{curProd.Art}</Col></Row>
          <Row><Col>Sprache:</Col><Col>{curProd.Sprache}</Col></Row>
          <Row className="mt-3"><Col>Bestellnummer:</Col><Col>{curProd.BestNr}</Col></Row>
          <Row><Col>Listenpreis inkl. MWSt.:</Col><Col>{curProd.Preis}</Col></Row>
          <Row className="mt-3">
            <Col>Anzahl in den Warenkorb:</Col>
            <Col className="d-inline-flex">
              <Form.Control id="inpMenge" 
                type="number" 
                ref={refMenge} 
                defaultValue={curProd.Menge > 0 ? curProd.Menge : ''}
                onKeyDown = { (e) => { if ( e.key === 'Enter' ) { handleAnzClick(e) } } }
              />
              <Button id="btnOK" onClick = { handleAnzClick } >
                OK
              </Button>
            </Col>
          </Row>
        </Col>
        <Col>
          <Row className="zentriert">
            <BildJPG className="BildJPG" BestNr={curProd.BestNr} />
          </Row>
          <Row>
            <Col className="zentriert d-inline-flex">
              <ButtonMP3 className="ButtonMP3" BestNr={curProd.BestNr} />
              <ButtonPDF className="ButtonPDF" BestNr={curProd.BestNr} Titel={curProd.Titel} />
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  ) 
}

//      <pre> <b>curProd:</b><br /> { JSON.stringify(curProd).replaceAll(',','\n') } </pre>


export default Produktdetails;

