import React from 'react';
import { config } from './+Global'
// import { Image } from 'react-bootstrap';

const Partner = () => {
  return (
    <embed 
      src={ config.uploadPath + 'partner.pdf' } 
      height="660"
      width="100%"
    />
  ) 
}

export default Partner;

//  <Image src={config.uploadPath + 'partner.jpg"} fluid />

/*
    <embed 
      src={ config.uploadPath + 'partner.pdf' } 
      height="660"
      width="100%"
    />
*/