import React from 'react';
import { Container, Row, Col, Table } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setCurProd } from './shopSlice';
import Suchen from './Suchen';
import { NavButton } from '../Buttons';
import { urheber } from '../+Global';
import './Shop.scss'

const Produktauswahl = () => {
  const products = useSelector( state => state.shop.products );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <Container id="Produktauswahl">
      <Container id="Steuerung">
        <Row>
          <Col id="Suchen">
            <Suchen /> 
          </Col>
          <Col className="Jumps" >
              <NavButton path="/bestellung" caption="Bestellung" />
              <NavButton path="/warenkorb" caption="Warenkorb" />
          </Col>
        </Row>
      </Container>
      <Table id="Liste" size="sm" striped borderless hover>
        <thead>
          <tr>
            <th>Titel</th>
            <th>Gruppe</th>
            <th>BestNr</th>
            <th>Preis</th>
          </tr>
        </thead>
        <tbody>
          { products.map( (prod, index) => (
            <tr key={index}
              onClick = { () => {
                dispatch( setCurProd( prod ) )
                navigate("/details")
              } }
            >
              <td>{prod.Titel + ' (' + urheber(prod) + ')'}</td>
              <td className="zenthori">{prod.Gruppe}</td>
              <td className="zenthori">{prod.BestNr}</td>
              <td className="zenthori">{prod.Preis}</td>
            </tr>
          ))}
        </tbody>
      </Table>

    </Container>
    
  ) 
}

export default Produktauswahl;
