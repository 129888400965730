import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { setCurProd } from './shopSlice'
import { urheber } from '../+Global';
import { NavButton } from '../Buttons'
// import { setCurProd } from './shopSlice';
import './Shop.scss';

/*
const nl2br = ( string ) => {
  if (string) return string.replace( '\n', '<br />')
}
*/
 
export const BestellMail = () => {
  const dispatch = useDispatch()
  const cart = useSelector( state => state.shop.cart )
  const products = useSelector( state => state.shop.products )
  const order = useSelector( state => state.shop.order )
  let prod = {};

  return (
    <Container id="BestellMail">
      <Row id="Steuerung">
        <Col className="Seitentitel">Bestell-Mail</Col>
        <Col>
          <NavButton path="/bestellung" caption="Bestellung" />
          <NavButton path="/warenkorb" caption="Warenkorb" />
          <NavButton path="/auswahl" caption="Auswahl" />
        </Col>
      </Row>
      <table width="100%">
        <tr>
          <td width="50%">
            <textarea value={order.name} 
                      style={{backgroundColor: '#261c79', color: 'yellow', width: '80%', border: 'none'}}
            /><br />
            <textarea value={order.addr} 
                      style={{backgroundColor: '#261c79', color: 'yellow', width: '80%', border: 'none'}}
            /><br />
          </td>
          <td>
            Mail: {order.mail}<br />
            Tel: {order.tel}<br /><br />
            UID: {order.uid}<br />
          </td>
        </tr>
        <tr>
            Zusatzinfo:<br/>
            {order.info}<br />
        </tr>
        <tr>
            Abweichende Lieferadresse:<br/>
            {order.lief}<br />
        </tr>
      </table>
      <h4>Wir bestellen zu Ihren Konditionen:</h4>
      <table width="100%">
        <thead>
          <tr>
            <th width="50%">Titel</th>
            <th width="20%">Gruppe</th>
            <th width="15%">BestNr</th>
            <th width="15%">Preis</th>
            <th width="10%">Menge</th>
          </tr>
        </thead>
        <tbody>
          { cart.map( (item, index) => (
            <tr 
              key={index}
              onChange = { (prod) = products.find( p => p.BestNr === item.BestNr ) }
              onClick = { () => { dispatch( setCurProd(item.BestNr) )
              } }
            >
              <td>{prod.Titel + ' (' + urheber(prod)  + ')'}</td>
              <td>{prod.Gruppe}</td>
              <td>{prod.BestNr}</td>
              <td>{prod.Preis}</td>
              <td>{item.Menge}</td>
            </tr>
          )
          )}
        </tbody>
      </table>
    </Container>
  )
}

//  onChange = { (prod) = prod.find( p => p.BestNr === item.BestNr ) }
//  onClick = { () => { dispatch( setCurProd(item.BestNr) ) } }
