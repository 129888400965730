import React          from 'react';
import { Container }  from 'react-bootstrap';
import { Route, Routes }      from "react-router-dom";
import Home           from './Home';
import Shop           from './shop/Shop'
import Produktauswahl from './shop/Produktauswahl'
import Aktuelles      from './shop/Aktuelles'
import Produktdetails from './shop/Produktdetails'
import Warenkorb      from './shop/Warenkorb'
import Bestellung     from './shop/Bestellung'
import { BestellMail }    from './shop/BestellMail'
import Partner        from './Partner';
import Kontakt        from './Kontakt';
import Rechtliches    from './rechtliches/Rechtliches'

const Content = () => {
  return (
    <Container style={{padding: '0.5em 0'}} fluid>
      <Routes>
        <Route path="/" element={<Home />} exact />
        <Route path="/shop/" element={<Shop />} />
        <Route path="/auswahl/" element={<Produktauswahl />} />
        <Route path="/aktuelles/" element={<Aktuelles />} />
        <Route path="/details/" element={<Produktdetails />} />
        <Route path="/warenkorb/" element={<Warenkorb />} />
        <Route path="/bestellung/" element={<Bestellung />} />
        <Route path="/bestellmail/" element={<BestellMail />} />
        <Route path="/partner/" element={<Partner />} />
        <Route path="/kontakt/" element={<Kontakt />} />
        <Route path="/rechtliches/" element={<Rechtliches />} />
      </Routes>
    </Container>
  ) 
}

export default Content
