import React from 'react'
import { Container, Nav, Navbar, Dropdown, DropdownButton } from 'react-bootstrap';
import { NavButton } from './Buttons';
import './Navigation.scss';
import './Buttons.scss';

const Menu = () => {
  
  const menuItems = [
    { nr: 0, caption: 'Home', path: '/' },
    { nr: 1, caption: 'Shop', path: '/auswahl' },
    { nr: 2, caption: 'Aktuelles', path: '/aktuelles' },
  //  { nr: 3, caption: 'Partner', path: '/partner' },
    { nr: 4, caption: 'Kontakt', path: '/kontakt' },
    { nr: 5, caption: 'Rechtliches', path: '/rechtliches' }
  ];

  return (
    <Container id="Menu">
      <Navbar id="Navbar" className="p-0 d-none d-md-inline-flex" >
        { menuItems.filter(item => item.nr > 0).map( item =>
          <Nav.Item key={item.nr} >
            <NavButton {...item} className ="blau" />
          </Nav.Item>
      )}
      </Navbar>
      <DropdownButton id="Dropdown" title="Menu" className="d-flex d-md-none">
        { menuItems.map( item =>
          <Dropdown.Item  key={item.nr}> 
            <NavButton {...item} className="ddItem"/>
          </Dropdown.Item>
        )}
      </DropdownButton>
    </Container>
  ) 
}

export default Menu;
