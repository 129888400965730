import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  files: [],
  products: [],
  cart: [],
  curprod: {}
}

const shopSlice = createSlice({
  name: 'shop',
  initialState, 
  reducers: {
    setFiles: (state, action) => {
      state.files = action.payload
    },
    setProducts: (state, action) => {
      state.products = action.payload
    },
    setCurProd: (state, action) => {
      state.curprod  = action.payload
    },
    putCart: (state, action) => {
      let idx = state.cart.findIndex( item => item.BestNr === action.payload.BestNr );
      if( idx >= 0 ) state.cart.splice( idx, 1 );   // wenn vorhanden, dann löschen
      if( action.payload.Menge > 0 ) state.cart.push(action.payload); // neu einfügen
    },
    setError: (state, action) => {
      state.error = action.payload
    } 

  }
});

export const {
  setFiles, 
  setProducts,
  setCurProd,
  putCart,
  setError
} = shopSlice.actions

export default shopSlice.reducer
