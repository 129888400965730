import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Menu from './Navigation.js';
import { LinkContainer } from 'react-router-bootstrap'
import { FuncButtons } from './Buttons.js';
import './Top.scss';

export default function Top() {

  return(
<>
    <Container id="Top" className="gold" fluid >
      <Row className="g-0">
        <Col id="Links" className="d-none d-sm-block col-auto" >
          <LinkContainer to="/" >
            <img src='../resources/LogoWMV.png' id="Logo" alt='' />
          </LinkContainer>
        </Col>
        <Col id="Mitte" >
          <Row className="mx-0" >
            <Col id="Titel" className="d-inline">
              <span id="Text1">WELTMUSIK&nbsp;</span>
              <span id="Text2" className="d-none d-sm-inline">EDITION&nbsp;</span>
              <span id="Text3" className="d-none d-md-inline">INTERNATIONAL</span>
            </Col>
          </Row>
          <Row>
            <Col id="Navi">
              <Menu />
              <div className="d-block d-md-none horizontal">
                <FuncButtons />
              </div>
            </Col>
          </Row>
        </Col>
        <Col id="Rechts" className="col-auto">
          <div className="d-none d-md-block vertikal">
            <FuncButtons />
          </div>
        </Col>
      </Row>
    </Container>
</>
  )
}
