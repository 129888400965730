import React from 'react';
import { Container } from 'react-bootstrap';
import './Rechtliches.scss';

const Impressum = () => {
  return (
    <Container className="textContainer" fluid>
      <h1 align="center">Allgemeine Geschäftsbedingungen (AGB)</h1>
      <ol>
        <li>GELTUNGSBEREICH</li>
        Die Lieferungen, Leistungen und Angebote der Edith Hochmuth Verlags- ges.m.b.H. (EHM) erfolgen ausschließlich aufgrund dieser AGB.
        Diese gelten somit auch für alle künftigen Geschäftsbeziehungen, auch wenn sie nicht nochmals ausdrücklich vereinbart werden. Gegenbestätigungen des Auftraggebers unter Hinweis auf seine Geschäfts- oder Lieferbedingungen wird hiermit widersprochen.
        Abweichungen von dieser AGB sind nur wirksam, wenn die EHM sie schriftlich bestätigt. Diese AGB bleiben auch dann verbindlich, wenn einzelne Teile aus irgendwelchen Gründen nicht wirksam sein sollten.
        <li>PREISANGEBOTE</li>
        Die im Angebot der EHM genannten Preise, werden als Fixpreise vereinbart. Die Preise der EHM schließen Verpackung, Fracht, Porto, Versicherung und sonstige Versandkosten nicht ein.
        Rabatte für Wiederverkäufer werden nur gegeben, wenn ein gültiger Handelsregisternachweis der EHM vorliegt.
        Die Rabatte unterliegen der jeweils gültigen Fassung der „Staffelpreise für Wiederverkäufer“ der EHM. Die „Staffelpreise für Wiederverkäufer“ werden auf Wunsch des Wiederverkäufers per PDF-Datei elektronisch übersandt.
        RECHNUNGSPREIS
        Die EHM fakturiert ihre Lieferungen und Leistungen mit dem Tage der Lieferung.
        <li>ZAHLUNGSBEDINGUNGEN</li>
        Die Zahlung ist innerhalb von 30 Kalendertagen ab Rechnungsdatum ohne Abzug zu leisten. Ein Skonto kann nicht gewährt werden.
        Zahlungen können nur per Banküberweisung erfolgen.
        <li>ZAHLUNGSVERZUG</li>
        Bei Zahlungsverzug sind Verzugszinsen von 10% über dem EURIBOR (Euro Interbank Offered Rate) zu zahlen. Die Geltendmachung weiteren Verzugsschadens wird hierdurch nicht ausgeschlossen.
        Der Auftraggeber verpflichtet sich für den Fall des Zahlungsverzuges, die Vergütungen des eingeschalteten Inkassoinstitutes zu ersetzen.
        Die EHM behält sich vor, Bestellungen nicht zu bearbeiten, wenn der Auftraggeber mit einem Auftrag mehr als 30 Tage im Zahlungsverzug ist.
        <li>LIEFERZEIT</li>
        Grundsätzlich wird die Bestellung des Auftraggebers innerhalb von 5 Werktagen bearbeitet. Bei Lieferhindernissen wird der Auftraggeber zeitnahe durch die EHM informiert.
        <li>LIEFERUNG</li>
        Die Lieferung der Bestellung des Auftraggebers erfolgt durch die EHM ausschließlich mittels der Österreichischen Post AG.
        <li>REKLAMATIONEN</li>
        Reklamationen werden nur innerhalb von 21 Tagen ab Rechnungsdatum anerkannt. (Das Schriftbild von gelieferten Notenexemplaren, die Ausführung der jeweiligen Arrangements, die Qualität der Tonaufnahme, etc., stellen keinen Grund zur Reklamation dar)
        <li>RÜCKTRITTSRECHT</li>
        Fest bezogene Waren können weder umgetauscht noch zurückgenommen werden.
        Ausnahme: Der Auftragsgeber gibt vor Lieferung durch die EHM bekannt, dass er seinen Auftrag widerruft. (Dieser Vorgang wird mittels des Datums der Postaufgabe durch die EHM kontrolliert) Der Widerruf ist nur 1 Werktag ab der Bestellaufgabe zulässig.
        <li>EIGENTUMSVORBEHALT</li>
        Alle Waren bleiben bis zur vollständigen Bezahlung Eigentum der EHM.
        <li>DATENSPEICHERUNG</li>
        Die EHM speichert die Daten des Auftraggebers lediglich zur Abwicklung des Geschäftsvorgangs und (gegebenenfalls) für weitere Geschäftsvorgänge. Eine anderweitige Verwendung ist auszuschließen. Die Daten des Auftraggebers aus dem Geschäftsvorgang werden bis zum Ablauf der steuerrechtlichen Aufbewahrungsfrist (7 Jahre) gespeichert.
        Lesen Sie dazu auch unsere Datenschutzerklärung (DSE).
        <li>ANZUWENDES RECHT, ERFÜLLUNGSORT, GERICHTSSTAND</li>
        Auf das Vertragsverhältnis findet ausschließlich österreichisches Recht Anwendung. Die Vertragssprache ist Deutsch.
        Als Erfüllungsort und Gerichtsstand wird unwiderruflich der Sitz der EHM vereinbart.
      </ol><br />
      <p className="absCenter">
          Stand 01/2021<br />
          Änderungen, Irrtümer und Satzfehler vorbehalten !<br /><br />
          <span className="textFett">EDITH HOCHMUTH VERLAGSGES.M.B.H</span><br />
          Grinzinger Straße 76 A, A-1190 WIEN<br />
          TEL +43 (0)1 / 370 15 05,&nbsp;&nbsp;
          FAX +43 (0)1 / 370 54 53<br />
          HG WIEN / FN: 106735p / ATU 14925509 / GERICHTSSTAND: WIEN
      </p>
    </Container>
  ) 
}

export default Impressum;


/*
          e-mail: <a href="mailto:office@weltmusik-vienna.at">office@weltmusik-vienna.at</a>,&nbsp;&nbsp;
          web: www.weltmusik-vienna.at<br />
*/