import React, {useState} from 'react';
import { useSelector } from 'react-redux';
import { Container, Form, Row, Col, Button, Modal } from 'react-bootstrap';
import { NavButton } from '../Buttons';
import { config, nl2br, urheber, PrintObject } from '../+Global';
import Warenkorb from './Warenkorb';
import axios from 'axios';
import './Shop.scss';

const Bestellung = () => {
  const cart = useSelector( state => state.shop.cart )
  const [state, setState] = useState({
    absender: '',
    empfaenger: 'bestellung@bumsfidel.at',
    betreff: 'Bestellung aus dem Weltmusik Shop',
    html: true,
    mailsent: false,
    error: null,
    modus: 1,   // 1 = nur Entwickler, 2 = +Absender, 3 = alle, 4 = ohne Entwickler
    showModal: false,
    validated: false
  })

  let order = {}
  
  const handleSubmit = (e) => {
    const form = e.currentTarget;
    if(form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    }
    setState(state => ({...state, 
      absender: order.mail, 
      nachricht: htmlMail(order,cart),
      validated: true
    }));
    axios.post( config.backendPath + 'mailer.php', {...state} )
      .then( r => { setState(state => ({...state, mailsent: r.data.sent, error: r.data.message })) } )
      .catch( e => { setState(state => ({...state, error: e.message })) } )
      setState(state => ({...state, showModal: true}));
    }

  const InputField = ({ label, name, type, defaultValue, rows, required }) => {
    return (
      <Row>
        <Col>
          { (type === 'textarea') && 
            <Form.Control as='textarea' name={name} rows={rows}
              className='input textarea'
              required={required}
              defaultValue={defaultValue}
              placeholder={label}
              onChange={e => order[e.target.name] = e.target.value} 
            />
          }
          { (type !== 'textarea') && 
            <Form.Control as="input" name={name} type={type} 
              className='input'
              required={required}
              defaultValue={defaultValue}
              placeholder={label}
              onChange={e => order[e.target.name] = e.target.value} 
            />
          }
        </Col>
      </Row>
    )
  }

  return (
    <Container id="Bestellung" fluid>
      <Form  validated={state.validated} onSubmit={handleSubmit} >
        <Row id="Steuerung">
          <Col className="Seitentitel">Bestellung</Col>
          <Col className="Jumps" >
              <NavButton path="/auswahl" caption="Auswahl" />
          </Col>
        </Row>
        <Row>
          <Col md="6">
            <InputField name="name" type="textarea" required={true}
              label="Name, Firma" defaultValue={order.name} />
            <InputField name="addr" type="textarea" required={true}
              label="Adresse" defaultValue={order.addr} /> 
            <InputField name="mail" type="email" required={true}
              label="eMail" defaultValue={order.mail} />
            <InputField name="tel"  type="text" required={true}
              label="Telefon" defaultValue={order.tel} />
            <InputField name="uid"  type="text" 
              label="UID-Nummer" defaultValue={order.uid} />
          </Col>
          <Col>
            <InputField name="lief" type="textarea" rows="3"
              label="Lieferadresse (wenn abweichend)" defaultValue={order.lief} />
            <InputField name="info" type="textarea" rows="4" 
              label="Ggf. Zusatzinfo" defaultValue={order.info} />
          </Col>
        </Row>
        <Row>
          <Col>
            <br />Hier können Sie Ihre Bestellung überabeiten, indem Sie auf 
            einen Artikel klicken und danach im Detailfenster die Menge ändern 
            (Bei Menge=0 wird der Artikel aus dem Warenkorb entfernt):
          </Col>
          <Warenkorb ohneTitel />
          <Col md="8">
            Mit der Absendung Ihrer verbindlichen Bestellung mittels 
            Bestellbutton bestätigen Sie unwiderruflich, dass Sie die 
            AGB und DSE der Edith Hochmuth Verlagsges.m.b.H. gelesen haben und 
            diese vollumfänglich akzeptieren.
          </Col>
          <Col className="zentriert">
            <Button id="Bestellbutton" type="submit" >
              Bestellung verbindlich absenden
            </Button>
            <Modal show={state.showModal} centered
              onHide={ () => setState(state => ({...state, showModal: false }))}  >
              <Modal.Header closeButton>
                <Modal.Title>Danke für Ihre Nachricht!</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <PrintObject {...state} />
              </Modal.Body>
            </Modal>
          </Col>
        </Row>
      </Form>
    </Container>
  )

}

export default Bestellung;

const htmlMail = (o,c) => {
  let summe = 0;
  let text = 
  '<html><head> \n'+
    '<title>Bestellmail</title> \n'+
    '<style> \n'+
      'body { font-family: sans-serif; } \n'+
      'table { width: 100%; } \n'+
      'td { vertical-align: top; } \n'+
      '.cart th {background-color: #DDDDDD;} \n'+
      '.cart td {text-align: center;} \n'+
      '.cart .links { text-align: left; } \n'+
    '</style> \n'+
  '</head><body> \n'+
    '<h3>Verbindliche Bestellung</h3> \n'+
    '<table><tr><td width="50%"> \n'+
      nl2br(o.name) + '<br />' + nl2br(o.addr) + 
    '</td><td> \n' +
      o.mail + '<br />' + o.tel + '<br />UID: ' + o.uid + 
    '</td></tr> \n'+
    '<tr><td> \n';
      if(o.lief) text += '<br /><b>Abweichende Lieferadresse:</b><br />' + nl2br(o.lief);
      text += '</td><td> \n'
      if(o.info) text += '<br /><b>Zusatzinfo:</b><br />' + nl2br(o.info);
    text += '</td></tr></table> \n';
    text +=
    '<br />Wir bestellen verbindlich, zu den von Ihnen genannten Bedingungen: \n'+
    '<table class="cart"> \n'+
      '<tr> \n'+
        '<th>Titel</th> \n'+
        '<th>Gruppe</th> \n'+
        '<th>BestNr</th> \n'+
        '<th>Preis</th> \n'+
        '<th>Anz.</th> \n'+
      '</tr> \n';
      c.forEach( i => {      // ...anders auch??
        summe += i.Preis * i.Menge;
        text +=
        '<tr> \n'+
          '<td class="links">' + i.Titel + ' (' + urheber(i) + ')</td> \n'+
          '<td>' + i.Gruppe + '</td> \n'+
          '<td>' + i.BestNr + '</td> \n'+
          '<td>' + i.Preis + '</td> \n'+
          '<td>' + i.Menge + '</td> \n'+
        '</tr> \n';
      } )
      text += 
      '<tr><td> </td><td> </td> \n'+
      '<td><b>Summe:</b></td> \n'+
      '<td ><b>' + summe.toFixed(2) + '</b></td> \n'+
      '<td> </td></tr> \n'+
    '</table></body></html>';
  return text;
}
