import React, { createRef } from 'react';
import { Container, Form, Button, Image, Col} from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { config } from '../+Global'
import { setProducts, setError } from './shopSlice';
import axios from 'axios';
import './Suchen.scss';

const Suchen = () => {
  const refSuchtext = createRef()
  const dispatch = useDispatch()

  const handleSuchenClick = () => {
    if( refSuchtext.current.value.length > 3 ) {
      console.log( "vor axios" )
      axios
      .post( config.backendPath + 'produktsuche.php', { suche: refSuchtext.current.value } )
      .then(  res => { dispatch(setProducts(res.data)) } )
      .catch( err => { dispatch(setError(err.message)) } )
      console.log( "nach axios" )
    } else {
      console.log( "nach else" )
      dispatch( setProducts([]) )   // damit kein leerer Wert zurückgegeben wird
    }
  }

  return (
    <Container>
      <Col className="d-inline-flex">
        <Form.Control id="Suchtext"
          type="text"
          ref={refSuchtext}
          placeholder="Produktauswahl Suchwort(e)..."
          onKeyDown = { (e) => { if( e.key === 'Enter' ) { handleSuchenClick(e) } } }
        />
        <Button id="Lupe" onClick = { handleSuchenClick } >
          <Image src = '/resources/lupe.png'/>
        </Button>
      </Col>
    </Container>
  )
}

export default Suchen;
