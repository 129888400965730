import React from 'react';
import { Container, Row, Col  } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { setProducts, setError, setCurProd } from './shopSlice';
import { config, urheber } from '../+Global'
import axios from 'axios';
import './Aktuelles.scss';

const Text = (p) => {
  return (
    <div className={p.className}>
      <p>
        {p.prod.Gruppe} <br />
        <b> {p.prod.Titel} </b><br />
        {urheber( p.prod )}
      </p>
    </div>
  )
} 

const Bild = (p) => {
  return (
    <div className={p.className}>
      <img alt='' title={"BestNr: " + p.prod.BestNr}
        src = { config.uploadPath + p.prod.BestNr + '.jpg' } 
        onError={ (e) => e.target.src=config.uploadPath + "default.jpg" }
      />
    </div>
  )
}

const Aktuelles = () => {
  const products = useSelector( state => state.shop.products )
  const dispatch = useDispatch()
  const navigate = useNavigate()

  axios
  .post( config.backendPath + 'produktsuche.php', {suche: '==AKTUELLES=='} )
  .then( res => { dispatch(setProducts(res.data)) } )
  .catch( err => { dispatch(setError(err.message)) } )

  return (
    <Container id="Aktuelles" onLoad = { () => {} } >
      <Row className="Titel">
        <h2>Aktuelles von Weltmusik</h2>
        <p>Auf eine Zeile klicken, um Details zum Artikel zu sehen. 
          Im Detailfenster kann dann auch bestellt werden.</p>
      </Row>
        { products.map( (prod, index) => (
          <Row key={index} className="rowAkt"
                onClick = { () => {
                  dispatch( setCurProd( prod ) );
                  navigate("/details")
                } } 
            >
            <Col className="d-inline-flex">
                <Bild className="bild" prod={prod} />
                <Text className="text" prod={prod} />
            </Col>
          </Row>
        ))}
    </Container>
  )
}

export default Aktuelles;
