import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './Rechtliches.scss';

const Impressum = () => {
  return (
    <Container className="textContainer" fluid>
      <h1>Impressum</h1>
      <Row><Col className="absLinks">
        <p className="textGross">
          EDITH HOCHMUTH VERLAGSGES.M.B.H
        </p>
        <p className="textFett">
          WELTMUSIK EDITION INTERNATIONAL<br />
          JOSEF HOCHMUTH MUSIKVERLAG<br />
          METROTON MUSIKVERLAG<br />
          EBERLE VERLAG
        </p>
        <p>
          Sitz: Wien<br />
          FN: 106735 p<br />
          Handelsgericht Wien<br />
          UID-Nummer: ATU 14924509<br />
          DVR: 0108219<br />
          Gerichtsstand: Wien
        </p>
      </Col></Row>
      <Row><Col className="absRechts">
        <p className="textGross">
          RUBATO MUSIKVERLAG GES.M.B.H<br />
        </p>
        <p className="textFett">
          VIENNA MUSIC MUSIKVERLAG<br />
          EDITION BALLROOMMUSIC<br />
        </p>
        <p>
          Sitz: Wien<br />
          FN: 38203 g<br />
          Handelsgericht Wien<br />
          UID-Nummer: ATU 13364401<br />
          DVR: 0732168<br />
          Gerichtsstand: Wien
        </p><br />
      </Col></Row>
      <Row><Col className="absCenter">
        <p>
          Geschäftsführung: Edith-Michaela Krupka-Dornaus<br />
          Prokurist / Musikalischer Leiter: Harald Lakits
        </p>
        <p>
          Grinzinger Straße 76 A<br />
          A-1190 Wien (Vienna, Austria)<br />
          TEL +43 (0)1 / 370 15 05<br />
          FAX +43 (0)1 / 370 54 53
        </p>
      </Col></Row>
    </Container>
  ) 
}

export default Impressum;

/*
<p>
e-mail: <a href="mailto:office@weltmusik-vienna.at">office@weltmusik-vienna.at</a><br />
web: <a href="https://www.weltmusik-vienna.at">www.weltmusik-vienna.at</a>
</p>
*/