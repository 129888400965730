import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { LinkContainer } from 'react-router-bootstrap'
import { setMuted, setUrl }  from './soundsSlice';
import { config } from './+Global.js';
import './Buttons.scss';


//==== NavButtons ===== Navigationsbuttons (Menu...)

export const NavButton = (props) => {
  return (
    <LinkContainer id="NavButton" to={props.path} className={props.className}>
      <p>{props.caption}</p>
    </LinkContainer>
  )
}

//==== FuncButtons ===== Funktionale Buttons (Sprache, Sound, Cart...)

export const FuncButtons = () => {
  return (
      <div id="FuncButtons">
        <LangButton />
        <SoundButton />
        <CartButton />
      </div>
  )
}

const LangButton = () => {      // vorläufig Dummy 
    return (
      <div href='#' id="LangButton" className="funcButton">
          <img href="#" src="/resources/FlaggeAT.png" alt='' className="funcImage" />
      </div >
    )
  }

const SoundButton = () => {
  const sounds = useSelector( state => state.sounds )
  const dispatch = useDispatch()
  return (
    <div id="SoundButton" className="funcButton"
      onClick={ () => { 
        if( sounds.url === '' ) dispatch( setUrl( config.uploadPath + "titelmusik.mp3" ) );
        dispatch( setMuted( !sounds.muted ) );
      } }
    >
      { sounds.muted && <img width="30px" src="/resources/SoundStart.png" alt='' className="funcImage" /> }
      {!sounds.muted && <img width="30px" src="/resources/SoundStop.png" alt='' className="funcImage" /> }
    </div>
  )
}

const CartButton = () => {
  const anzCart = useSelector( state => state.shop.cart ).length;
  return ( 
    <LinkContainer to="/warenkorb" id="CartButton" >
      <div className="funcButton">
        <img src="/resources/ShoppingCart.png" alt='' className="funcImage" />
        <span className="CartText"> { anzCart > 0 ? anzCart : '' } </span>
      </div>
    </LinkContainer>
  )
}
