import { React, useRef } from 'react';

export const config = {
  infoText:       '23.07.2024',
  servicesPath:   'https://services.weltmusik-vienna.at/',
  backendPath:    'https://backend.weltmusik-vienna.at/',
  uploadPath:     'https://upload.weltmusik-vienna.at/'
}

export function urheber(prod) {
  switch(prod.Gruppe) {
    case "Noten":     return( prod.Komponist )
    case "Tonträger": return( prod.IntOrch )
    case "Bücher":    return( prod.Autor )
    case "Spiele":    return( prod.Autor )
    default:          return "URHEBER NICHT DEFINIERT!"
  }
}

//*** Zum Testen/Debuggen:

export const PrintObject = (o) => {
  return(
    <pre> 
      { JSON.stringify({o}).replaceAll(',','\n') } 
    </pre>
  )
}

export const ShowWindowSize = () => {
  const windowSize = useRef([window.innerWidth, window.innerHeight]);
  return ( <p>{windowSize.current[0]}</p> )
}

export const nl2br = (s) => {
  if(s) return s.replaceAll('\n','<br />')
  else return '';
}

//***