import React from 'react';
import { Container } from 'react-bootstrap';
import './Rechtliches.scss';

const Impressum = () => {
  return (
    <Container className="textContainer" fluid>
      <h1>Datenschutzerklärung (DSE)</h1>
      <ol>
        <li>DATENSCHUTZINFORMATIONEN</li>
          Der Schutz Ihrer persönlichen Daten ist und ein besonderes Anliegen. Wir verarbeiten die von Ihnen an uns übermittelten Daten, ausschließlich auf Grundlage der gesetzlichen Bestimmungen (DSGVO, TKG 2003). In diesen Datenschutzinformationen informieren wir Sie über die wichtigsten Aspekte unserer Datenverarbeitung.

        <li>KONTAKTAUFNAHME per E-MAIL</li>
          Wenn Sie per E-Mail mit uns Kontakt aufnehmen, werden ihre Daten bei uns nicht automatisch gespeichert. Im Falle eines Geschäftsvorganges werden Ihre Daten in einer externen, nicht automatisierten Datenbank aufgenommen. Ihre Daten aus dem Geschäftsvorgang werden bis zum Ablauf der steuerrechtlichen Aufbewahrungsfrist (7 Jahre) gespeichert. Anfragen über Werke mit Relevanz zu unseren Copyrights werden bei Bedarf in unserem externen, nicht automatisierten Copyrightverzeichnis abgelegt. Ihre Daten werden in keinem Fall an Dritte weitergegeben.

        <li>BESTELLUNGEN ÜBER UNSERE WEBSITE</li>
          Wenn Sie eine Bestellung über unsere Website tätigen, bestätigen Sie uns, dass Sie unsere AGB & DSE gelesen und akzeptiert haben. Ihre Daten aus dem Geschäftsvorgang werden in einer externen, nicht automatisierten Datenbank aufgenommen. Ihre Daten aus dem Geschäftsvorgang werden bis zum Ablauf der steuerrechtlichen Aufbewahrungsfrist (7 Jahre) gespeichert. Ihre Daten werden in keinem Fall an Dritte
          weitergegeben.
        <li>WEBSITE – www.weltmusik-vienna.at</li>
          Wir verwenden auf unserer Website keine permanenten Cookies und kein Web-Analysesystem. Ebenso wird Ihre IP-Adresse bei uns nicht erfasst.
        <li>RECHTSAUSKUNFT</li>
          Ihnen steht grundsätzlich das Recht auf Auskunft, Berichtigung, Löschung, Einschränkung, Datenübertragbarkeit, Widerruf und Widerspruch zu.<br />
          Ihr Ansprechpartner in unserem Verlagshaus: Hr. Harald Lakits
      </ol>
      <br />
      <p className="absCenter">
          Stand 01/2021<br />
          Änderungen, Irrtümer und Satzfehler vorbehalten !<br /><br />
          <span className="textFett">EDITH HOCHMUTH VERLAGSGES.M.B.H</span><br />
          Grinzinger Straße 76 A, A-1190 WIEN<br />
          TEL +43 (0)1 / 370 15 05,&nbsp;&nbsp;
          FAX +43 (0)1 / 370 54 53<br />
          HG WIEN / FN: 106735p / ATU 14925509 / GERICHTSSTAND: WIEN
      </p>
    </Container>
  ) 
}

export default Impressum;

/*
          e-mail: <a href="mailto:office@weltmusik-vienna.at">office@weltmusik-vienna.at</a>,&nbsp;&nbsp;
          web: www.weltmusik-vienna.at<br />
*/