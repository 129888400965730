import React, {useState} from 'react';
import { Container, Form, Button, Row, Col, Image, Modal } from 'react-bootstrap';
import { config, PrintObject } from './+Global'
import axios from 'axios';
import './Kontakt.scss';

const Kontakt = () => {
  
  const [ form, setForm ] = useState ( {
    absender: '',
    betreff: '',
    nachricht: '',
    empfaenger: "office@bumsfidel.at",    // 'office@weltmusik-vienna.at',
    modus: 1    // 1 = nur Entwickler, 2 = +Absender, 3 = alle, 4 = ohne Entwickler
  } )
  
  const [ responseData, setResponseData ] = useState({ sent: false, data: ''});
  const [ responseCode, setResponseCode ] = useState( 0 );
  const [ showModal, setShowModal ] = useState( false );
  
  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    axios.post( config.backendPath + 'mailer.php', form )
      .then( (resp) => { setResponseData (resp.data) })
//      .catch( (code) => { setResponseCode (code.data) })   // http_response_code - wie??
      setShowModal(true);
  };

  return (
    <Container id="Kontakt" fluid>
      <Row md={2}>
        <Col id="Text">
          Gerne nehmen wir auch Ihre telefonischen Anfragen entgegen!<br /><br />
          Ihr Ansprechpartner: <br />
          <b>Prok. Harald Lakits</b> <br />
          Tel. +43 (0)664 / 34 62 418
        </Col>
        <Col id="Bild">
          <Image src="/resources/weltmusik.png" rounded fluid className="flex" />
          <Container className="d-none">
            { PrintObject( responseData ) }
            { PrintObject( responseCode) }
          </Container>
        </Col>
      </Row>
      <Row><Col>
        <Form id="Form" onSubmit={e=>handleSubmit(e)}>
            <Form.Label>Ihre Nachricht an uns:</Form.Label>
            <Form.Control type="email" required placeholder={ 'Ihre Mailadresse...' }
                onChange = { (e) => setForm( { ...form, absender: e.target.value } ) } />
            <Form.Control type="text" required placeholder={ 'Ihr Betreff...' }
                onChange = { (e) => setForm( { ...form, betreff: e.target.value } ) } />
            <Form.Control as ="textarea" rows="3" required placeholder={ 'Ihre Nachricht...' }
                onChange = { (e) => setForm( { ...form, nachricht: e.target.value } ) } />
            <Button id="Submit" type="submit" variant="secondary" >
                Absenden
            </Button>
            <Modal show={showModal} centered onHide={ () => setShowModal( false ) } >
              <Modal.Header closeButton>
                <Modal.Title >
                  {  responseData.sent && 'Danke für Ihre Nachricht!' }
                  { !responseData.sent && 'Ooops, da ist etwas schiefgelaufen!' }
                </Modal.Title>
              </Modal.Header>
            </Modal>
        </Form>
      </Col></Row>
    </Container>
  )
}

export default Kontakt;
