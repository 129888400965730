import React from 'react';
import { Container, Row, Col, Table } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setCurProd } from './shopSlice'
import { NavButton } from '../Buttons';
import { urheber } from '../+Global';
import './Shop.scss'

const Warenkorb = (p) => {
  const dispatch = useDispatch()
  const cart = useSelector( state => state.shop.cart )
  const navigate = useNavigate();
  let summe = 0;

  return (
    <Container id="Warenkorb" fluid >
      { (!p.ohneTitel) &&
        <Row id="Steuerung">
          <Col className="Seitentitel"> Warenkorb </Col>
          <Col className="Jumps" >
              <NavButton path="/bestellung" caption="Bestellung" />
              <NavButton path="/auswahl" caption="Auswahl" />
          </Col>
        </Row>
      }
      <Table id="Liste" size="sm" striped borderless hover>
        <thead>
          <tr>
            <th>Titel</th>
            <th>Gruppe</th>
            <th>BestNr</th>
            <th>Preis</th>
            <th>Anz.</th>
          </tr>
        </thead>
        <tbody>
          { cart.map( (item) => (
            <tr 
              dummy = { summe += item.Menge * item.Preis }
              onClick = { () => { 
                dispatch( setCurProd(item) )
                navigate('/details')
              } }
            >
              <td>{item.Titel + ' (' + urheber(item) + ')'}</td>
              <td className="zenthori">{item.Gruppe}</td>
              <td className="zenthori">{item.BestNr}</td>
              <td className="zenthori">{item.Preis}</td>
              <td className="zenthori">{item.Menge}</td>
            </tr>
           ) )
          }
        </tbody>
        <thead>
          <tr>
            <th></th>
            <th></th>
            <th>Summe:</th>
            <th>{summe.toFixed(2)}</th>
            <th></th>
          </tr>
        </thead>
      </Table>
    </Container>
  ) 
}

//      <pre> <b>cart:</b><br /> { JSON.stringify(cart).replaceAll(',','\n') } </pre>

export default Warenkorb;
