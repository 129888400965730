import React from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import Impressum from './Impressum'
import GeschBed from './Geschbed'
import Datenschutz from './Datenschutz'

const Rechtliches = () => {
  return (
    <Tabs  variant="pills" fill>
      <Tab eventKey="impressum" title="Impressum"> <Impressum /> </Tab>
      <Tab eventKey="geschbed" title="Geschäftsbedingungen"> <GeschBed /> </Tab>
      <Tab eventKey="datenschutz" title="Datenschutzerklärung"> <Datenschutz /> </Tab>
    </Tabs>  
  ) 
}

export default Rechtliches;
