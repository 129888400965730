import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  url: '',
  muted: true,
  showInfo: false
}

const soundsSlice = createSlice({
  name: 'sounds',
  initialState, 
  reducers: {
    setUrl: (state, action) => {
      state.url = action.payload;
    },
    setMuted: (state, action) => {
      state.muted = action.payload;
    }

  }
})

export const {setUrl, setMuted} = soundsSlice.actions
export default soundsSlice.reducer
