import { configureStore } from '@reduxjs/toolkit'
// import naviReducer from './naviSlice'
import soundsReducer from './soundsSlice'
import shopReducer from './shop/shopSlice'

export default configureStore({
  reducer: {
//    navi: naviReducer,
    sounds: soundsReducer,
    shop: shopReducer
  }
})
