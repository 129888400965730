import React from 'react';
import { Container } from 'react-bootstrap';
import { BrowserRouter as Router } from "react-router-dom";
import Produktfiles from './shop/Produktfiles.js';
import Top from './Top.js';
import Content from './Content.js'
import Player from './Player.js';

export default function App() {

  return(
    <Container fluid>
      <Produktfiles />
      <Player id="meinPlayer" />
      <Router>
        <Top />
        <Content />
      </Router>
    </Container>
  )

}
