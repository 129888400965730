import React from 'react';
import { useSelector } from 'react-redux';
import ReactAudioPlayer from 'react-audio-player';

const Player = () => {
  const sounds = useSelector( state => state.sounds );

  return (
    <>
      <ReactAudioPlayer
        src = { sounds.url }
        autoPlay 
        muted = { sounds.muted }
        loop
        // controls
      />
    </>
  ); 
}

export default Player

//  <span>{sounds.url}:&nbsp; {sounds.muted ? 'MUTED' : 'PLAYING'} </span>
