import React from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import Produktauswahl from './Produktauswahl'
import Produktdetails from './Produktdetails'
import Warenkorb from './Warenkorb'
import Bestellung from './Bestellung'
// import './Shop.scss'

const Shop = (p) => {
  // const dispatch = useDispatch()
  const redirect = useSelector( state => state.navi.redirect )
  var tab = redirect.tab    // parseVariable( 'tab' )
  if( !tab ) tab = "auswahl"
  const cart = useSelector( state => state.shop.cart )
  const txtCart = ( cart.length > 0 ? ' (' + cart.length + ')' : '' )
  return (
    <Tabs variant="tabs" id="tbsShop" defaultActiveKey={ tab } >
      <Tab eventKey="auswahl" title="Produktauswahl">
        <Produktauswahl />
      </Tab>
      <Tab eventKey="details" title="Produktdetails">
        <Produktdetails />
      </Tab>
      <Tab eventKey="warenkorb" title={"Warenkorb" + txtCart }>
        <Warenkorb />
      </Tab>
      <Tab eventKey="bestellung" title="Bestellung">
        <Bestellung />
      </Tab>
    </Tabs>  
  ) 
}

export default Shop;


//       onSelect = { (k) => dispatch( setTab( k ) ) }
