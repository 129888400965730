import React from 'react';
import { Container, Image } from 'react-bootstrap';
import './Home.scss';
import { config } from './+Global'


const Home = () => {
  return (
    <Container>
      <p className="homeText">
        EDITH HOCHMUTH VERLAGSGES.M.B.H.<br />
        RUBATO MUSIKVERLAG GES.M.B.H.
      </p>
      <Image src="/resources/weltmusik.png" fluid />
      <p style={{ textAlign: "center", color: "yellow" }}> <br />{config.infoText} </p>
    </Container>
  ) 
}

export default Home;
